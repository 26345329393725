import store from "../redux/create-store";

declare const window: any;

export function initCTRAttributes() {
  window.sendCTRItemToAmazonConnect = window.sendCTRItemToAmazonConnect || {}
}

export function updateVaoEligibilityFlag(conversationId: string, value: string) {
  if (typeof window.updateCTRItem === 'function') {
    return window.updateCTRItem(conversationId, { vaoEligibilityFlag: `${conversationId}: ${value}` })
  }
}

export function updateConsentSend(conversationId: string, value: string) {
  if (typeof window.updateCTRItem === 'function') {
    return window.updateCTRItem(conversationId, { consentSend: `${conversationId}: ${value}` })
  }
}

export function updateConsentReceived(conversationId: string, value: string) {
  if (typeof window.updateCTRItem === 'function') {
    return window.updateCTRItem(conversationId, { consentReceived: `${conversationId}: ${value}` })
  }
}

export function updateOfferPitchFlag(conversationId: string, value: string) {
  if (typeof window.updateCTRItem === 'function') {
    return window.updateCTRItem(conversationId, { offerPitchFlag: `${conversationId}: ${value}` })
  }
}

export function updateOfferAcceptedFlag(conversationId: string, value: string) {
  if (typeof window.updateCTRItem === 'function') {
    return window.updateCTRItem(conversationId, { offerAcceptedFlag: `${conversationId}: ${value}` })
  }
}

export function updateCustomerSearchClick(conversationId: string, value: string) {
  if (typeof window.updateCTRItem === 'function') {
    return window.updateCTRItem(conversationId, { customerSearchClick: `${conversationId}: ${value}` })
  }
}
// latest pitched offer id
export function updateOfferId(conversationId: string, value: string) {
  if (typeof window.updateCTRItem === 'function') {
    return window.updateCTRItem(conversationId, { offerId: `${conversationId}: ${value}` })
  }
}

export function updateValueAddActionSales(conversationId: string, value: string) {

  if (typeof window.updateCTRItem === 'function') {
    return window.updateCTRItem(conversationId, { valueAddActionSales: value })
  }
}

export function updateValueAddActionSupport(conversationId: string, value: string) {
  if (typeof window.updateCTRItem === 'function') {
    return window.updateCTRItem(conversationId, { valueAddActionSupport: value })
  }
}

export function updateCTRConsentAnalytics(consentStatus: string, allCampaginNames: Array<string>, isSalesContact?: boolean) {
  const currentConvId = getCurrConvId();
  const uniqueCampaignNames = allCampaginNames.filter((item, index) => allCampaginNames.indexOf(item) === index).join(', ');

  if (isSalesContact === true) {
    // By default, set the consent status to YES for sales contacts
    updateConsentSend(currentConvId, 'Yes');
    updateConsentReceived(currentConvId, 'Yes');
    updateValueAddActionSales(currentConvId, uniqueCampaignNames);
    updateValueAddActionSupport(currentConvId, '');
    return;
  } else {
    updateValueAddActionSupport(currentConvId, uniqueCampaignNames);
    updateValueAddActionSales(currentConvId, '');
  }
  // By default, set the consent status to No
  switch (consentStatus) {
    case 'sent':
      updateConsentSend(currentConvId, 'Yes');
      break;
    case 'accepted':
      updateConsentSend(currentConvId, 'Yes');
      updateConsentReceived(currentConvId, 'Yes');
      break;
    case 'rejected':
      updateConsentSend(currentConvId, 'Yes');
      updateConsentReceived(currentConvId, 'Yes');
      break;
    default:
      updateConsentSend(currentConvId, 'No');
      updateConsentReceived(currentConvId, 'No');
  }
}

export function updateCTROfferAnalytics(initialContactId: string, allCampaginNames: Array<string>, isSalesContact: boolean, customerId: string) {
  const currentConvId = getCurrConvId();
  const currentState = store.getState().RUInfo.convData;
  const convDataKey = initialContactId + customerId
  const latestOffer = findLatestOffer(currentState[convDataKey]?.cards);
  const latestOfferStatus = latestOffer?.offerStatus;
  const uniqueCampaignNames = allCampaginNames.filter((item, index) => allCampaginNames.indexOf(item) === index).join(', ');

  if (isSalesContact) {
    updateValueAddActionSupport(currentConvId, '');
    updateValueAddActionSales(currentConvId, uniqueCampaignNames);
    updateConsentSend(currentConvId, 'Yes');
    updateConsentReceived(currentConvId, 'Yes');
  } else {
    updateValueAddActionSupport(currentConvId, uniqueCampaignNames);
    updateValueAddActionSales(currentConvId, '');
  }

  if (!!latestOffer && typeof latestOffer === 'object') {
    updateOfferId(currentConvId, latestOffer.offerId);

    switch (latestOfferStatus) {
      case 'pitched':
        updateOfferPitchFlag(currentConvId, 'Yes');
        break;
      case 'accepted':
        updateOfferPitchFlag(currentConvId, 'Yes');
        updateOfferAcceptedFlag(currentConvId, 'Yes');
        break;
      case 'rejected':
        updateOfferPitchFlag(currentConvId, 'Yes');
        updateOfferAcceptedFlag(currentConvId, 'No');
        break;
      default:
        updateOfferPitchFlag(currentConvId, 'No');
        updateOfferAcceptedFlag(currentConvId, 'No');
    }
  }
}

function findLatestOffer(cards) {
  let latestOffer = null;
  let latestTimestamp = 0;

  Array.isArray(cards) && cards?.forEach(card => {
    if (card.lastUpdatedTimestamp > latestTimestamp) {
      latestTimestamp = card.lastUpdatedTimestamp;
      latestOffer = card;
    }
  });
  return latestOffer;
}

function getCurrConvId() {
  if (typeof window.getCurrentConvID === 'function') {
    return window.getCurrentConvID();
  }
  return "";
}
