import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import Refreshs from '../icons/Refresh'
import { ActionButton, Text } from '@adobe/react-spectrum'
import localization from '../Language/localization'
import Carousel from './carousel/carousel-component'
import styles from '../styles/WidgetFrame.module.css'
import ConsentPage from './consent/consent-component';
import { initDunamis } from '../Analytics/vao-danalytics'
import { updateVaoEligibilityFlag, updateCTRConsentAnalytics } from '../Analytics/vao-ctr-analytics'
import themes from '../themes/index'
import { COLLAPSE_RU_DETAILS_HANDLER, EXPANDED_VIEW } from '../redux/constants/ru-constants'
import { fetchCustomerOffers } from '../redux/actions/ru-actions';
import { RootState } from '../redux/reducers'
import { useTheme } from '../themes/ThemeContext';
import { ContextRUModuleData } from '../interfaces'

interface Props {
  contextRUModuleData: ContextRUModuleData
  callBackEvent: (event: any, eventData: object) => void
}

const WidgetFrame = ({ contextRUModuleData, callBackEvent }: Props) => {
  const { customerId, urls, queueName, convId, channel, darkMode, isVoiceContact, currentConvId, chatStatus, collapseRUDetailsFromOAC, isSalesContact, defaultImage } = contextRUModuleData;
  const { RUInfo: { convData = [], fetchOffersErrors = {} } = {} } = useSelector((state: RootState) => state);
  const convDataKey = convId + customerId;
  const { consentStatus } = convData?.[convDataKey] || {};
  const [cards, setCards] = useState(convData?.[convDataKey]?.cards || []);
  const { darkModeBG, darkModeBorder, darkModeTitle } = themes(darkMode);
  const language = 'en';
  const localizeString = localization[language];
  const dispatch: any = useDispatch();
  const [vaoWorkflowCategory, setVaoWorkflowCategory] = useState(`${isSalesContact ? 'Sales' : 'Support'} ${isVoiceContact ? 'Telephony' : 'Chat'}`);
  const { theme } = useTheme();
  const [isDNLContact, setIsDNLContact] = useState(queueName.includes('DNL-'))

  // Fetching the latest queue name from the queue path
  useEffect(() => {
    initDunamis();
    setCards(convData?.[convDataKey]?.cards || []);

    // for analytics
    // setVaoWorkflowCategory(`${isSalesContact ? 'Sales' : 'Support'} ${isVoiceContact ? 'Telephony' : 'Chat'}`);
  }, []);

  useEffect(() => {
    setCards(convData?.[convDataKey]?.cards || []);

    if (cards.length === 0) {
      callBackEvent(EXPANDED_VIEW, { expanded: false });
      updateVaoEligibilityFlag(currentConvId, 'No')
    } else {
      updateVaoEligibilityFlag(currentConvId, 'Yes')
    }
  }, [convId, customerId, chatStatus]);

  useEffect(() => {
    // This useEffect is to update the CTR/danalytics
    const allCampaignNames = cards?.map(card => card.title) || []

    // for analytics
    setVaoWorkflowCategory(`${isSalesContact ? 'Sales' : 'Support'} ${isVoiceContact ? 'Telephony' : 'Chat'}`);
    updateCTRConsentAnalytics(consentStatus, allCampaignNames, isSalesContact);
    setIsDNLContact(queueName.includes('DNL-'));
  }, [convId, customerId, chatStatus, isSalesContact, isVoiceContact, queueName]);

  // you can reuse this method if you want
  const handleRefresh = () => {
    if (customerId) {
      dispatch(fetchCustomerOffers(convId, customerId, urls?.PERSONALIZATION_DOMAIN, queueName));
    }
  }

  useEffect(()=>{
    if(cards.length === 0 || fetchOffersErrors[convId]) {

      callBackEvent(COLLAPSE_RU_DETAILS_HANDLER, {})
    }
  }, [collapseRUDetailsFromOAC])

  return (
    <div style={{ background: theme.background, color: theme.color, borderColor: theme['border-color'] }} className={`${styles.recommended__upgrades__container} ${darkModeBG}`} id='recommended-upgrades-id'>
      <div className={`${styles.ru__header} ${darkModeBG} ${darkModeBorder} ${darkModeTitle}`}>
        <div className={`${styles.icon__button} ${styles.ru__icon__background}`}>{localizeString?.ru_icon_button}</div>
        <span>{localizeString?.ru_title}</span>
        <div className={`${styles['ru-refresh-button']}  ${darkModeTitle}`}>
          <ActionButton
            UNSAFE_className={`${styles['ru-refresh-action-button']} ${darkModeTitle}`}
            isQuiet
            onPress={handleRefresh}
          >
            <Refreshs />
          </ActionButton>
        </div>
      </div>
      {fetchOffersErrors[convId] ? (
        <div
          className={`${styles.ru_disabled_box}`}
        >
          <Text UNSAFE_style={{ backgroundColor: theme["box1-color"] }} UNSAFE_className={`${styles.no_consent_text}`}>
            Error While Fetching Offers
          </Text>
        </div>
      ) : cards.length === 0 ? (
        <div
          className={`${styles.ru_disabled_box}`}
        >
          <Text UNSAFE_style={{ backgroundColor: theme["box1-color"] }} UNSAFE_className={`${styles.no_consent_text}`}>
            Customer is not eligible for any upgrades
          </Text>
        </div>
      ) : (
        <React.Fragment>
          <div
            className={`${isSalesContact || isDNLContact ? styles.ru__body__sales : styles.ru__body
              }`}
          >
            <Carousel
              cards={cards}
              channel={channel}
              callBackEvent={callBackEvent}
              urls={urls}
              customerId={customerId}
              convId={convId}
              currentConvId={currentConvId}
              queueName={queueName}
              darkMode={darkMode}
              isSalesContact={isSalesContact}
              isVoiceContact={isVoiceContact}
              defaultImage={defaultImage}
              vaoWorkflowCategory={vaoWorkflowCategory}
              collapseRUDetailsFromOAC={collapseRUDetailsFromOAC }
            />
          </div>
          {isSalesContact === false && (
            <ConsentPage
              callBackEvent={callBackEvent}
              isVoiceContact={isVoiceContact}
              isSalesContact={isSalesContact}
              convId={convId}
              currentConvId={currentConvId}
              customerId={customerId}
              urls={urls}
              vaoWorkflowCategory={vaoWorkflowCategory}
              darkMode={darkMode}
              cards={cards}
            />
          )}
        </React.Fragment>
      )}
    </div>
  )
}

export default WidgetFrame
